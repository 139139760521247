import axios from 'axios';
import { ROL_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const createRol = async (newRol) => {
  return axios.post(`${ROL_PATH}/guardar`, newRol);
};

export const editRol = async (rol) => {
  return axios.put(`${ROL_PATH}/editar`, rol);
};

export const assignRol = async (idUsuario, rol) => {
  return axios.post(`${ROL_PATH}/asignar?idUsuario=${idUsuario}`, rol);
};

export const getRolList = async () => {
  return axios.get(`${ROL_PATH}/listado`);
};

export const getRolsNumber = async () => {
  return axios.get(`${ROL_PATH}/contar`);
};

export const getRolListPaged = async (size, page, sortType1, direction) => {
  return axios.get(
    `${ROL_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};
export const getAllRolesWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${ROL_PATH}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const getRolDetails = async (rolId) => {
  return axios.get(`${ROL_PATH}/detalle?id=${rolId}`);
};

export const deleteRol = async (rolId) => {
  return axios.delete(`${ROL_PATH}/eliminar?id=${rolId}`);
};
