import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Grid,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import PropTypes from 'prop-types';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';

const ModalInviteUser = ({ open, rolList, handleClose, formik, loading }) => {
  const [t] = useTranslation();

  const handleCloseOutside = (event, reason) => {
    if (reason && reason === 'backdropClick' && loading) return;
    handleClose();
  };

  const handleTouchClose = () => {
    if (!loading) handleClose();
  };

  return (
    <Dialog
      open={open}
      // TransitionComponent={Transition}
      // keepMounted
      onClose={handleCloseOutside}
      // fullWidth
      // aria-labelledby="alert-dialog-slide-title"
      // aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        variant="h4"
        sx={{
          backgroundColor: '#ffbe3c',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {t('Configuration.ModalUser')}
        <IconButton style={{ float: 'right', marginTop: -12 }} onClick={handleTouchClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
        <Grid >
                <CustomFormLabel htmlFor='email'>{t('TableHeader.Email')}</CustomFormLabel>
                <CustomTextField
                  id='email'
                  name='email'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                />
              </Grid>
              <Grid>
                  <CustomFormLabel htmlFor='rolSeleccionado'>{t('Loads.Setting.Select-a-role')}</CustomFormLabel>
                  <CustomSelect
                    id='rolSeleccionado'
                    name='rolSeleccionado'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.rolSeleccionado || ''}
                    error={Boolean(formik.touched.rolSeleccionado && formik.errors.rolSeleccionado)}
                    variant="outlined"
                    fullWidth
                    size="medium"
                  >
                    {rolList?.length > 0 &&
                      rolList?.map((rol) => {
                        return (
                          <MenuItem key={rol.id} value={rol}>
                            {rol.nombre}
                          </MenuItem>
                        );
                      })}
                  </CustomSelect>
                  {formik.touched.rolSeleccionado && formik.errors.rolSeleccionado ? (
                    <FormHelperText sx={{ color: '#ffbf35', marginLeft: '16px !important' }}>
                      {formik.touched.rolSeleccionado && formik.errors.rolSeleccionado}
                    </FormHelperText>
                  ) : null}
                </Grid>
          <Box>
            <Button
              variant="contained"
              type="submit"
              sx={{
                mt: 4,
                ml: 53,
              }}
              disabled={loading}
            >
              {t('Configuration.InviteButton')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
ModalInviteUser.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ModalInviteUser;
