import React from 'react';
import {
  Button,
  DialogActions,
  Grid,
  Typography,
  Box,
  useTheme,
  IconButton,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';

function ModalAction({ action, onClose }) {
  const [t] = useTranslation();
  const theme = useTheme();
  if (action.name === 'Aceptar') {
    return (
      <Box id="titulo-formulario" width={1} p={2} pt={1} pb={1}>
        <Grid container direction="column" spacing={2}>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <FeatherIcon icon="info" color={theme.palette.primary.main} />
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <FeatherIcon icon="x" />
              </IconButton>
            </Grid>
          </Grid>
          <Divider component="div" sx={{ width: '100%', ml: 1 }} />
          <Grid item>
            <Typography variant="h3">{action.header}</Typography>
          </Grid>

          <Grid item xs={12}>
            {action.body}
          </Grid>
          <Divider component="div" sx={{ width: '100%', ml: 1 }} />
          {action && (
            <Grid item container justifyContent="flex-end">
              <DialogActions>
                {action.action && (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      <Typography variant="body1">{t('Button.Cancel')}</Typography>
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        onClose();
                        action.action();
                      }}
                    >
                      <Typography variant="body1">{action.buttonLabel || action.title}</Typography>
                    </Button>
                  </>
                )}
              </DialogActions>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }

  return (
    <Box id="titulo-formulario" width="500px" p={2} pt={1} pb={1}>
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <FeatherIcon icon="help-circle" color={theme.palette.primary.main} />
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <FeatherIcon icon="x" />
            </IconButton>
          </Grid>
        </Grid>

        <Divider component="div" sx={{ width: '100%', ml: 1 }} />

        <Grid item>
          <Typography variant="h3">{action.header}</Typography>
        </Grid>

        <Grid item xs={11} sx={{ mb: 2 }}>
          {action.body}
        </Grid>

        <Divider component="div" sx={{ width: '100%', ml: 1, mb: 1 }} />
        {action && (
          <Grid item container justifyContent="flex-end" xs={12}>
            <DialogActions>
              {action.action && (
                <>
                  <Button variant="outlined" color="primary" size="small" onClick={onClose}>
                    <Typography variant="body1">{t('Button.Cancel')}</Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      onClose();
                      action.action();
                    }}
                  >
                    <Typography variant="body1">{action.buttonLabel || action.title}</Typography>
                  </Button>
                </>
              )}
            </DialogActions>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default ModalAction;
